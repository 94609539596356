import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { PosterFragmentShader } from './poster.fragment'
import { PosterVertexShader } from './poster.vertex'
import img1 from '../../../assets/images/img1.png'
import img2 from '../../../assets/images/img2.png'
import img3 from '../../../assets/images/img3.png'
import img4 from '../../../assets/images/img4.png'
import img5 from '../../../assets/images/img5.png'
import img6 from '../../../assets/images/img6.png'
import { descriptions } from '../../../pages/Description/description-data'
import { threeComponent } from '../../../interfaces/threeComponent.interface'

const Posters: FunctionComponent<threeComponent> = ({
  pageCtrl,
  windowSize,
  getTouchRef,
}) => {
  let { currentPage } = pageCtrl

  const [hovered, setHovered] = useState(false)

  /*
   * Variables
   */
  let IMAGE_WIDTH = (0.75 * windowSize.width) / 200
  let IMAGE_HEIGHT = (3.5 / 6) * IMAGE_WIDTH
  let SCENE_OFFSET_X = -0.5
  if (windowSize.width <= 950 && windowSize.height >= 450) {
    SCENE_OFFSET_X = 0
    IMAGE_WIDTH = (1.25 * windowSize.width) / 200
    IMAGE_HEIGHT = (3.5 / 6) * IMAGE_WIDTH
  }
  const IMAGE_SEPARATION = 25
  const PI = 3.141592653

  /*
   * Loader
   */
  const textures = useLoader(TextureLoader, [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  ])
  textures.forEach((texture) => {
    texture.anisotropy = 8
  })

  /*
   * Refs Meshes and Uniforms
   */
  const plan1Ref = useRef<THREE.Mesh>(null)
  const plan2Ref = useRef<THREE.Mesh>(null)
  const plan3Ref = useRef<THREE.Mesh>(null)
  const plan4Ref = useRef<THREE.Mesh>(null)
  const plan5Ref = useRef<THREE.Mesh>(null)
  const plan6Ref = useRef<THREE.Mesh>(null)
  const plansRefs = [plan1Ref, plan2Ref, plan3Ref, plan4Ref, plan5Ref, plan6Ref]

  let UNIFORMS_INIT_VALUE = {
    u_time: { value: 0.0 },
    u_resolution: {
      value: new THREE.Vector4(
        windowSize.width * window.devicePixelRatio,
        windowSize.height * window.devicePixelRatio,
        1.0,
        1.0
      ),
    },
    u_windowSize: {
      value: new THREE.Vector2(windowSize.width, windowSize.height),
    },
    u_xRot: { value: 0 },
  }
  const uniforms1Ref = useRef({
    texture1: { value: textures[0] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms2Ref = useRef({
    texture1: { value: textures[1] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms3Ref = useRef({
    texture1: { value: textures[2] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms4Ref = useRef({
    texture1: { value: textures[3] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms5Ref = useRef({
    texture1: { value: textures[4] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms6Ref = useRef({
    texture1: { value: textures[5] },
    ...UNIFORMS_INIT_VALUE,
  })
  const uniforms = [
    uniforms1Ref,
    uniforms2Ref,
    uniforms3Ref,
    uniforms4Ref,
    uniforms5Ref,
    uniforms6Ref,
  ]

  /*
   * UseEffect
   */
  useEffect(() => {
    if (uniforms.length !== 0) {
      uniforms.forEach((uniform) => {
        uniform.current.u_resolution.value = new THREE.Vector4(
          windowSize.width * window.devicePixelRatio,
          windowSize.height * window.devicePixelRatio,
          1.0,
          1.0
        )
        uniform.current.u_windowSize.value = new THREE.Vector2(
          windowSize.width,
          windowSize.height
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  useEffect(() => {
    if (currentPage !== null) {
      if (descriptions[currentPage].url !== '') {
        document.body.style.cursor = hovered ? 'pointer' : 'auto'
      }
    }
  }, [currentPage, hovered])

  /*
   * UseFrame
   */
  useFrame(() => {
    let gestureDist = 0
    if (getTouchRef && getTouchRef().current.active) {
      gestureDist = getTouchRef().current.x - getTouchRef().current.start
    }

    plansRefs.forEach((plan, index) => {
      if (plan.current !== null) {
        if (currentPage !== null) {
          if (gestureDist !== 0) {
            let dx =
              SCENE_OFFSET_X -
              IMAGE_SEPARATION * (currentPage - index) +
              gestureDist * 0.015 -
              plan.current.position.x
            let vx = dx * 0.1
            if (vx > 0.001 || vx < -0.001) {
              plan.current.position.x += vx
            }
          } else {
            let dx =
              SCENE_OFFSET_X -
              IMAGE_SEPARATION * (currentPage - index) -
              plan.current.position.x
            let vx =
              (Math.min(
                Math.abs(IMAGE_SEPARATION - Math.abs(dx)),
                Math.abs(IMAGE_SEPARATION * 2 - Math.abs(dx))
              ) +
                0.03) *
              0.015 *
              dx
            if (vx > 0.001 || vx < -0.001) {
              plan.current.position.x += vx
            }
          }

          let dxRot =
            Math.max(
              -0.2,
              -Math.abs(
                (SCENE_OFFSET_X -
                  IMAGE_SEPARATION * (currentPage - index) -
                  plan.current.position.x) *
                  0.5
              )
            ) - plan.current.rotation.x
          let vxRot = dxRot * 0.2
          if (vxRot > 0.001 || vxRot < -0.001) {
            plan.current.rotation.x += vxRot
          }

          uniforms[index].current.u_xRot.value = plan.current.rotation.x
        } else {
          let dx =
            25 +
            SCENE_OFFSET_X -
            IMAGE_SEPARATION * (0 - index) -
            plan.current.position.x
          let vx = Math.max(Math.abs(25 - dx), 0.01) * 0.015 * dx
          if (vx > 0.001 || vx < -0.001) {
            plan.current.position.x += vx
          }

          let dxRot =
            Math.min(0, Math.abs((15 - plan.current.position.x) * 0.5) - 0.2) -
            plan.current.rotation.x
          let vxRot = dxRot * 0.2
          if (vxRot > 0.001 || vxRot < -0.001) {
            plan.current.rotation.x += vxRot
          }
        }
      }
    })

    uniforms.forEach((uniform) => {
      uniform.current.u_time.value += 0.015
      if (uniform.current.u_time.value >= 40 * PI) {
        uniform.current.u_time.value = 0
      }
    })
  })

  /*
   * Handlers
   */
  const redirectToUrl = (index: number) => {
    if (descriptions[index].url !== '') {
      document.location.href = descriptions[index].url
    }
  }

  return (
    <>
      {textures.map((texture, index: number) => (
        <mesh
          onClick={() => redirectToUrl(index)}
          ref={plansRefs[index]}
          onPointerOver={() => setHovered(true)}
          onPointerOut={() => setHovered(false)}
          key={index}
          position={[
            25 + SCENE_OFFSET_X - IMAGE_SEPARATION * (0 - index),
            0,
            0,
          ]}>
          <planeGeometry args={[IMAGE_WIDTH, IMAGE_HEIGHT, 8, 4]} />
          <shaderMaterial
            transparent={true}
            fragmentShader={PosterFragmentShader}
            vertexShader={PosterVertexShader}
            uniforms={uniforms[index].current}
          />
          {/* <meshPhysicalMaterial map={texture} side={DoubleSide} clearcoat={0.5} transparent /> */}
        </mesh>
      ))}
    </>
  )
}

export default Posters
