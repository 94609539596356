export const LineFragmentShader = /* GLSL */ `
#ifdef GL_ES
precision mediump float;
#endif

uniform float u_time;
uniform vec4 u_resolution; 
uniform vec2 u_windowSize; 
uniform sampler2D texture1;

varying vec3 pos;
varying vec2 vUv;

float pi =3.1415926535;

float rightdist (float x){
    return 1.0-cos( ((1.0-x)-0.05) * 5.0 );
}

float leftdist (float x){
    if(u_windowSize.x >= 950.0) {
        return 1.0-cos((0.25 - x) * 3.0);
    } else {
        return 1.0-cos((0.05 - x) * 5.0);
    }
}

void main() {
    vec2 st = gl_FragCoord.xy/u_resolution.xy;
    vec2 uv = vUv;

    float rightActivation = 1.0-step(0.05,1.0-st.x);
    float leftActivation = u_windowSize.x >= 950.0?step(0.75,1.0-st.x):step(0.95,1.0-st.x);

    float sideshades = leftActivation*leftdist(st.x) + rightActivation*rightdist(st.x);

    //vec2 newUv = (uv.xy - vec2(0.5))*(1.0+sideshades*5.0) + vec2(0.5);
    vec2 newUv = vec2(uv.x, (uv.y - 0.5)*(1.0+sideshades*5.0) + 0.5);

    vec4 t = texture2D(texture1,newUv);
    vec4 wave = vec4( vec3(1.0), (1.0-sideshades*2.0)*(cos(cos(u_time*0.3)*pos.x*0.6)*cos(u_time*0.3)*sin(u_time*0.8)*0.2+0.3) );
    gl_FragColor = t*wave;
}
`
