import { FunctionComponent, useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { windowSize } from '../../../interfaces/windowSize.interface'

interface canvasSettingsComponent {
  windowSize: windowSize
}

const CanvasSettings: FunctionComponent<canvasSettingsComponent> = ({
  windowSize,
}) => {
  const { gl, camera } = useThree()
  gl.setPixelRatio(window.devicePixelRatio)

  useEffect(() => {
    let CAM_OFFSET_Y =
      windowSize.width <= 950 && windowSize.height >= 450 ? -1 : 0
    let CAM_ZOOM_CORRECTION = 800 / windowSize.height

    camera.position.y = CAM_OFFSET_Y
    camera.zoom = CAM_ZOOM_CORRECTION
    camera.updateProjectionMatrix()
  }, [camera, windowSize])

  return null
}

export default CanvasSettings
