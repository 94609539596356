import { FunctionComponent, useState, useMemo, useRef, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { MdChevronRight } from 'react-icons/md'
import { gsap } from 'gsap'
import './accueil.scss'
import { FormattedMessage } from 'react-intl'
import { pageComponent } from '../../interfaces/pageComponent.interface'

const Accueil: FunctionComponent<pageComponent> = ({ pageCtrl }) => {
  let { setCurrentPage } = pageCtrl

  const [linkTag, setLinkTag] = useState(<div></div>)

  const tEnter = useMemo(() => gsap.timeline(), [])
  const tLeave = useMemo(() => gsap.timeline(), [])

  /*
   * Refs
   */
  const titleRef = useRef<HTMLHeadingElement>(null)
  const contentRef = useRef<HTMLParagraphElement>(null)
  const linkRef = useRef<HTMLButtonElement>(null)

  /*
   * useEffects
   */
  useEffect(() => {
    setCurrentPage(null)
    enterAnimation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
   * Handlers
   */
  let goToPortfolio = () => {
    leaveAnimation(() => {
      setLinkTag(<Redirect to="/portfolio" />)
    })
  }

  let leaveAnimation = (onCompleteFn: () => void) => {
    tEnter.clear()
    if (!tLeave.isActive()) {
      tLeave.clear()
      tLeave
        .fromTo(
          linkRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.2,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
          }
        )
        .fromTo(
          contentRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.2,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
          },
          '-=0.1'
        )
        .fromTo(
          titleRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.2,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
            onComplete: () => {
              onCompleteFn()
            },
          },
          '-=0.1'
        )
    }
  }

  let enterAnimation = () => {
    tEnter.clear()
    tEnter
      .fromTo(
        titleRef.current,
        {
          opacity: 0,
          transform: 'translateY(0rem) rotateX(0deg)',
        },
        {
          delay: 0.5,
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        }
      )
      .fromTo(
        contentRef.current,
        {
          opacity: 0,
          transform: 'translateY(2rem) rotateX(30deg)',
        },
        {
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        },
        '-=0.2'
      )
      .fromTo(
        linkRef.current,
        {
          opacity: 0,
          transform: 'translateY(2rem) rotateX(30deg)',
        },
        {
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        },
        '-=0.2'
      )
  }

  return (
    <div id="accueil-container">
      <div>
        <h3 ref={titleRef}>
          <FormattedMessage id="homeTitle" />
        </h3>
        <p ref={contentRef}>
          <FormattedMessage id="homeText" />
        </p>
        <button
          data-testid="portfolio-btn"
          ref={linkRef}
          onClick={() => {
            goToPortfolio()
          }}>
          Portfolio
          <MdChevronRight />
        </button>
      </div>
      {linkTag}
    </div>
  )
}

export default Accueil
