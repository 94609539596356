import { FunctionComponent, useState, useMemo, useRef, useEffect } from 'react'
import './description.scss'
import { MdChevronRight, MdChevronLeft, MdHome } from 'react-icons/md'
import { AiOutlineLink } from 'react-icons/ai'
import { descriptions } from './description-data'
import { gsap } from 'gsap'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { description } from '../../interfaces/description.interface'
//import { pageComponent } from '../../interfaces/pageComponent.interface'

const Description: FunctionComponent<any> = ({ pageCtrl, touchDelta }) => {
  let { currentPage, setCurrentPage } = pageCtrl

  const [isLeaveAnimOver, setIsLeaveAnimOver] = useState<boolean>(false)
  const [projectInfo, setProjectInfo] = useState<description>(descriptions[0])
  const [linkTag, setLinkTag] = useState(false)

  const tEnter = useMemo(() => gsap.timeline(), [])
  const tLeave = useMemo(() => gsap.timeline(), [])

  /*
   * Refs
   */
  const titleRef = useRef<HTMLHeadingElement>(null)
  const contentRef = useRef<HTMLParagraphElement>(null)
  const tagsRef = useRef<HTMLDivElement>(null)

  /*
   * Handlers
   */
  let leaveAnimation = (onCompleteFn: () => void) => {
    tEnter.clear()
    if (!tLeave.isActive()) {
      tLeave.clear()
      tLeave
        .fromTo(
          tagsRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.4,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
          }
        )
        .fromTo(
          contentRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.4,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
          },
          '-=0.2'
        )
        .fromTo(
          titleRef.current,
          {
            opacity: 1,
            transform: 'translateY(0rem) rotateX(0deg)',
          },
          {
            duration: 0.2,
            transform: 'translateY(2rem) rotateX(30deg)',
            opacity: 0,
            onComplete: () => {
              onCompleteFn()
            },
          },
          '-=0.2'
        )
    }
  }

  let enterAnimation = () => {
    tEnter.clear()
    tEnter
      .fromTo(
        titleRef.current,
        {
          opacity: 0,
          transform: 'translateY(0rem) rotateX(0deg)',
        },
        {
          delay: 0.1,
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        }
      )
      .fromTo(
        contentRef.current,
        {
          opacity: 0,
          transform: 'translateY(2rem) rotateX(30deg)',
        },
        {
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        },
        '-=0.2'
      )
      .fromTo(
        tagsRef.current,
        {
          opacity: 0,
          transform: 'translateY(2rem) rotateX(30deg)',
        },
        {
          duration: 0.4,
          transform: 'translateY(0rem) rotateX(0deg)',
          opacity: 1,
        },
        '-=0.2'
      )
  }

  const changeCurrentPage = (increment: number) => {
    if (currentPage !== null) {
      let newValue = currentPage + increment
      if (newValue >= 0 && newValue < descriptions.length) {
        setCurrentPage(currentPage + increment)
      } else if (newValue < 0) {
        setCurrentPage(descriptions.length - 1)
      } else if (newValue >= descriptions.length) {
        setCurrentPage(0)
      }
      leaveAnimation(() => setIsLeaveAnimOver(true))
    }
  }

  let returnToAccueil = () => {
    leaveAnimation(() => setLinkTag(true))
  }

  /*
   * useEffects
   */
  useEffect(() => {
    setCurrentPage(0)
    enterAnimation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLeaveAnimOver && currentPage !== null) {
      setProjectInfo(descriptions[currentPage])
      enterAnimation()
    }
    setIsLeaveAnimOver(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeaveAnimOver])

  useEffect(() => {
    if (touchDelta < -50) {
      changeCurrentPage(1)
    } else if (touchDelta > 50 && currentPage !== 0) {
      changeCurrentPage(-1)
    } else if (touchDelta > 50 && currentPage === 0) {
      returnToAccueil()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchDelta])

  /*
   * JSX
   */
  let tags = projectInfo.techs.map((name: string, index: number) => (
    <span key={index}>{name}</span>
  ))

  let backBtn =
    currentPage === 0 ? (
      <button
        data-testid="home-btn"
        onClick={() => {
          returnToAccueil()
        }}>
        <MdHome id="home-svg" />
      </button>
    ) : (
      <button
        data-testid="back-btn"
        onClick={() => {
          changeCurrentPage(-1)
        }}>
        <MdChevronLeft />
      </button>
    )

  let nextBtn =
    currentPage !== null && currentPage !== descriptions.length - 1 ? (
      <button
        data-testid="next-btn"
        onClick={() => {
          changeCurrentPage(1)
        }}>
        <MdChevronRight />
      </button>
    ) : (
      <div id="empty-nav-btn"></div>
    )

  let carouselIndicator = (
    <div id="carousel-container">
      {descriptions.map((e, index) => (
        <button
          key={index}
          className={currentPage === index ? 'carousel-selected-btn' : ''}
          onClick={() =>
            changeCurrentPage(index - (currentPage || 0))
          }></button>
      ))}
    </div>
  )

  let urlLink =
    projectInfo.url !== '' ? (
      <a href={projectInfo.url}>
        <FormattedMessage id={projectInfo.title} />
        <AiOutlineLink />
      </a>
    ) : (
      <FormattedMessage id={projectInfo.title} />
    )

  return (
    <div id="description-container">
      <div id="layout-container">
        {backBtn}
        <div id="section-container">
          <div className="description-section">
            <h3 ref={titleRef}>{urlLink}</h3>
            <p ref={contentRef}>
              <FormattedMessage id={projectInfo.content} />
            </p>
            <div ref={tagsRef}>{tags}</div>
          </div>
        </div>
        {nextBtn}
        {linkTag ? <Redirect to="/" /> : null}
      </div>
      {carouselIndicator}
    </div>
  )
}

export default Description
