export const descriptions = [
  // {
  //     title:"Water Back",
  //     content:`Une application mobile développée en React Native pour créer un réseaux communautaire autour des carences d'eau en Afrique.`,
  //     techs:['React.js','gsap']
  // },
  // {
  //     title:"Office Applications",
  //     content:`Application de gestion administrative et financière, récupération et affichage des données, gestion de la base de données.`,
  //     techs:['React.js','Nest.js']
  // },
  {
    title: 'title1',
    content: 'content1',
    techs: [
      'ReactNative',
      'Redux',
      'Expo',
      'Express.js',
      'MongoDB',
      'Supertest',
    ],
    url: '',
  },
  {
    title: 'title2',
    content: 'content2',
    techs: [
      'React',
      'Redux',
      'Typescript',
      'SCSS',
      'Nest.js',
      'TypeORM',
      'PostgreSQL',
      'Jest',
      'Jenkins',
      'Docker',
    ],
    url: '',
  },
  {
    title: 'title3',
    content: 'content3',
    techs: ['React', 'Typescript', 'GSAP', 'SCSS'],
    url: 'https://hdc-pageslider-clone.netlify.app/',
  },
  {
    title: 'title4',
    content: 'content4',
    techs: ['React.js', 'IntersectionObservers'],
    url: 'https://hdc-qoveryclone.netlify.app/',
  },
  {
    title: 'title5',
    content: 'content5',
    techs: ['React.js', 'CSS'],
    url: 'https://hdc-notnetflix.netlify.app/',
  },
  {
    title: 'title6',
    content: 'content6',
    techs: ['Tensorflow.js', 'JQuery', 'CSS'],
    url: 'https://IA-flappy.netlify.app/',
  },
]
