import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { headerComponent } from '../../../interfaces/headerComponent.interface'
import './header.scss'

const Header: FunctionComponent<headerComponent> = ({
  currentLanguage,
  switchLanguage,
}) => {
  return (
    <div className="header">
      <div>
        <Link to="/">
          <h2>Hubert de Carcouët</h2>
        </Link>
        <span data-testid="header-subtitle">
          <FormattedMessage id="headerJobSubtitle" />
        </span>
      </div>
      <button className="header__language-box" onClick={() => switchLanguage()}>
        <div
          style={{
            marginLeft: currentLanguage.locale === 'fr-FR' ? '0rem' : '1.5rem',
          }}>
          <span>{currentLanguage.locale === 'fr-FR' ? 'FR' : 'EN'}</span>
        </div>
      </button>
    </div>
  )
}

export default Header
