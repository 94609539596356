/* eslint-disable jsx-a11y/alt-text */
import { FunctionComponent, Suspense, useState, useEffect, useRef } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Accueil from './pages/Accueil/Accueil'
import Description from './pages/Description/Description'
import ThreeCanvas from './components/ThreeFiber/ThreeCanvas/ThreeCanvas'
import Header from './components/React/Header/Header'
import Footer from './components/React/Footer/Footer'
import Loading from './components/React/Loading/Loading'
import MouseLerp from './components/React/MouseLerp/MouseLerp'
import { IntlProvider } from 'react-intl'
import frMessages from './languages/fr-FR.json'
import enMessages from './languages/en-US.json'
import { windowSize } from './interfaces/windowSize.interface'
import { currentLanguage } from './interfaces/currentLanguage.interface'
import { touchRef } from './interfaces/touchRef.interface'
import './app.scss'

const App: FunctionComponent = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [currentPage, setCurrentPage] = useState<number | null>(null)
  const [currentLanguage, setCurrentLanguage] = useState<currentLanguage>({
    locale: 'en-US',
    messages: enMessages,
  })
  const [touchDelta, setTouchDelta] = useState(0)

  const appContainerRef = useRef<HTMLDivElement>(null)
  const touchRef = useRef<touchRef>({ start: 0, x: 0, end: 0, active: false })

  /*
   * UseEffect
   */
  useEffect(() => {
    if (document.readyState === 'complete') {
      setIsLoaded(true)
    }

    let resizeAppContainer = () => {
      appContainerRef.current?.style.setProperty(
        'height',
        `${window.innerHeight}px`
      )
      appContainerRef.current?.style.setProperty(
        'width',
        `${window.innerWidth}px`
      )
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    let handleReadyState = (event: Event) => {
      if ((event.target as HTMLDocument).readyState !== 'complete') {
        setIsLoaded(false)
      } else {
        setIsLoaded(true)
      }
    }

    document.addEventListener('readystatechange', (event) => {
      handleReadyState(event)
    })
    window.visualViewport.addEventListener('resize', () => {
      resizeAppContainer()
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    })
    resizeAppContainer()

    document.addEventListener('touchstart', (event) => {
      if (touchRef.current) {
        touchRef.current.start = event.touches[0].clientX
        touchRef.current.x = event.touches[0].clientX
        touchRef.current.active = true
      }
    })
    document.addEventListener('touchend', (event) => {
      if (touchRef.current) {
        touchRef.current.end = event.changedTouches[0].clientX
        setTouchDelta(touchRef.current.end - touchRef.current.start)
        touchRef.current.active = false
      }
    })
    document.addEventListener('touchmove', (event) => {
      if (touchRef.current) {
        touchRef.current.x = event.touches[0].clientX
      }
    })

    return () => {
      document.removeEventListener('touchstart', (event) => {
        if (touchRef.current) {
          touchRef.current.start = event.touches[0].clientX
          touchRef.current.x = event.touches[0].clientX
          touchRef.current.active = true
        }
      })
      document.removeEventListener('touchend', (event) => {
        if (touchRef.current) {
          touchRef.current.end = event.changedTouches[0].clientX
          setTouchDelta(touchRef.current.end - touchRef.current.start)
          touchRef.current.active = false
        }
      })
      document.removeEventListener('touchmove', (event) => {
        if (touchRef.current) {
          touchRef.current.x = event.touches[0].clientX
        }
      })

      document.removeEventListener('readystatechange', (event) => {
        handleReadyState(event)
      })
      window.visualViewport.removeEventListener('resize', () => {
        resizeAppContainer()
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      })
    }
  }, [])

  let getTouchRef = () => {
    return touchRef
  }

  /*
   * Handlers
   */
  let switchLanguage = () => {
    currentLanguage.locale === 'fr-FR'
      ? setCurrentLanguage({ locale: 'en-US', messages: enMessages })
      : setCurrentLanguage({ locale: 'fr-FR', messages: frMessages })
  }

  /*
   * JSX
   */
  let loader = isLoaded ? null : <Loading />

  return (
    <IntlProvider {...currentLanguage}>
      <div id="app-container" ref={appContainerRef}>
        <div>
          <Router>
            {loader}
            <Header
              currentLanguage={currentLanguage}
              switchLanguage={switchLanguage}
            />
            <MouseLerp />
            <Suspense fallback={<Redirect to="/" />}>
              <ThreeCanvas
                pageCtrl={{ currentPage, setCurrentPage }}
                windowSize={windowSize}
                getTouchRef={getTouchRef}
              />
              <Switch>
                <Route exact path="/">
                  <Accueil
                    pageCtrl={{
                      currentPage,
                      setCurrentPage,
                    }}
                  />
                </Route>
                <Route exact path="/portfolio">
                  <Description
                    pageCtrl={{
                      currentPage,
                      setCurrentPage,
                    }}
                    touchDelta={touchDelta}
                  />
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Suspense>
            <Footer />
          </Router>
        </div>
        <div id="tree-image"></div>
      </div>
    </IntlProvider>
  )
}

export default App
