export const PosterFragmentShader = /* GLSL */ `
#ifdef GL_ES
precision mediump float;
#endif

uniform vec4 u_resolution; 
uniform vec2 u_windowSize; 
uniform sampler2D texture1;
varying vec2 vUv;


float pi =3.1415926535;

float rightdist (float x){
    return 1.0-cos( ((1.0-x)-0.05) * 5.0 );
}

float leftdist (float x){
    if(u_windowSize.x <= 950.0 && u_windowSize.y >= 450.0) {
        return 1.0-cos((0.05 - x) * 5.0);
    } else {
        return 1.0-cos((0.25 - x) * 3.0);
    }
}

void main() {
    vec2 st = gl_FragCoord.xy/u_resolution.xy;

    vec2 uv = vUv;

    float rightActivation = 1.0-step(0.05,1.0-st.x);
    float leftActivation = (u_windowSize.x <= 950.0 && u_windowSize.y >= 450.0)?step(0.95,1.0-st.x):step(0.75,1.0-st.x);

    float sideshades = leftActivation*leftdist(st.x) + rightActivation*rightdist(st.x);

    //vec2 newUv = (uv.xy - vec2(0.5))*(1.0+sideshades*5.0) + vec2(0.5);
    vec2 newUv = vec2(uv.x, (uv.y - 0.5)*(1.0+sideshades*5.0) + 0.5);

    vec4 t = texture2D(texture1,newUv);
    gl_FragColor = t;
}
`
