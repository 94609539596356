import React, { FunctionComponent } from 'react'
import './loading.scss'

const Loading: FunctionComponent = () => {
  return (
    <div id="loading-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading
