export const PosterVertexShader = /* GLSL */ `
uniform mediump float u_time;
uniform mediump float u_xRot;
varying mediump vec3 pos;
varying mediump vec2 vUv;

void main() {
    vUv = uv;
    pos = position;
    pos.z += pos.x*pos.x*0.08*u_xRot;
    pos.z += u_xRot*5.0;
    pos.y += cos(u_time*0.5)*0.03;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
}

`
