import { FunctionComponent, useEffect, useRef } from 'react'
import throttle from 'lodash.throttle'
import { mouseCoord } from '../../../interfaces/mouseCoord.interface'
import './MouseLerp.scss'

const MouseLerp: FunctionComponent = () => {
  let mouseCoord = useRef<mouseCoord>({ x: 0, y: 0 })
  let mouseLerpDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function sigmoid(x: number) {
      return 1 / (1 + Math.pow(Math.E, -(x / 8)))
    }

    let lerp = (
      mouseX: number,
      mouseY: number,
      elementX: number,
      elementY: number
    ) => {
      let speed = 0.06
      let vX = (mouseX - elementX) * speed
      let vY = (mouseY - elementY) * speed
      return { x: elementX + vX, y: elementY + vY, vX, vY }
    }

    let moveElement = (
      nextX: number,
      nextY: number,
      vX: number,
      vY: number
    ) => {
      let scale = sigmoid(Math.sqrt(vX * vX + vY * vY)) - 0.5
      mouseLerpDivRef.current!.style.top = `${nextY}px`
      mouseLerpDivRef.current!.style.left = `${nextX}px`
      mouseLerpDivRef.current!.style.transform = `scale(${
        1 - scale * 0.6
      }) translate(-50%, -50%)`

      requestAnimationFrame(() => {
        let newPos = lerp(
          mouseCoord.current.x,
          mouseCoord.current.y,
          nextX,
          nextY
        )
        moveElement(newPos.x, newPos.y, newPos.vX, newPos.vY)
      })
    }

    window.addEventListener(
      'mousemove',
      throttle((e) => {
        mouseCoord.current.x = e.clientX
        mouseCoord.current.y = e.clientY
      }, 50)
    )

    moveElement(0, 0, 0, 0)
  }, [])

  return <div id="mouse-lerp" ref={mouseLerpDivRef}></div>
}

export default MouseLerp
