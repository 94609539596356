import { FunctionComponent, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three'
import './threeCanvas.scss'

import CanvasSettings from '../CanvasSettings/CanvasSettings'
import AnimatedLine from '../AnimatedLine/AnimatedLine'
import Posters from '../Posters/Posters'
import { threeComponent } from '../../../interfaces/threeComponent.interface'

const ThreeCanvas: FunctionComponent<threeComponent> = ({
  pageCtrl,
  windowSize,
  getTouchRef,
}) => {
  return (
    <div id="canvas-container">
      <Canvas style={windowSize}>
        <CanvasSettings windowSize={windowSize} />
        <ambientLight intensity={0.1} />
        <hemisphereLight
          color={new THREE.Color('grey')}
          groundColor={new THREE.Color('darkgrey')}
          position={[0, 0, 20]}
        />
        <Suspense fallback={null}>
          <AnimatedLine pageCtrl={pageCtrl} windowSize={windowSize} />
          <Posters
            pageCtrl={pageCtrl}
            windowSize={windowSize}
            getTouchRef={getTouchRef}
          />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default ThreeCanvas
