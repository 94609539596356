import React, { FunctionComponent, useEffect, useRef } from 'react'
import * as THREE from 'three'
import { useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
// import { vertices } from './lineVertices';
import { LineFragmentShader } from './line.fragment'
import { LineVertexShader } from './line.vertex'
import whiteImg from '../../../assets/images/whitetexture.png'
import { threeComponent } from '../../../interfaces/threeComponent.interface'

const AnimatedLine: FunctionComponent<threeComponent> = ({
  pageCtrl,
  windowSize,
}) => {
  let { currentPage } = pageCtrl

  /*
   * Variables
   */
  let LINE_WIDTH = (0.3 * windowSize.height) / 300
  //const SCENE_OFFSET_X = windowSize.width>950?-0.8:0;
  const IMAGE_SEPARATION = 15 * 0.5
  const PI = 3.141592653

  /*
   * Loader
   */
  const [whiteTexture] = useLoader(TextureLoader, [whiteImg])
  whiteTexture.wrapS = THREE.RepeatWrapping
  whiteTexture.wrapT = THREE.ClampToEdgeWrapping
  whiteTexture.anisotropy = 4

  /*
   * Refs Meshes and Uniforms
   */
  const lineRef = useRef<THREE.Mesh>(null)

  const uniforms = useRef({
    texture1: { value: whiteTexture },
    u_time: { value: 0.0 },
    u_resolution: {
      value: new THREE.Vector4(
        windowSize.width * window.devicePixelRatio,
        windowSize.height * window.devicePixelRatio,
        1.0,
        1.0
      ),
    },
    u_windowSize: {
      value: new THREE.Vector2(windowSize.width, windowSize.height),
    },
  })

  /*
   * UseEffect
   */
  useEffect(() => {
    uniforms.current.u_resolution.value = new THREE.Vector4(
      windowSize.width * window.devicePixelRatio,
      windowSize.height * window.devicePixelRatio,
      1.0,
      1.0
    )
    uniforms.current.u_windowSize.value = new THREE.Vector2(
      windowSize.width,
      windowSize.height
    )
  }, [windowSize])

  /*
   * UseFrame
   */
  useFrame(() => {
    if (lineRef.current !== null) {
      if (currentPage !== null) {
        let dx =
          10 - IMAGE_SEPARATION * currentPage - lineRef.current.position.x
        // lineRef.current.position.x += ((-6 - IMAGE_SEPARATION  * - (currentPage) - lineRef.current.position.x)*0.01)
        lineRef.current.position.x += dx * 0.01
      } else {
        lineRef.current.position.x += (-10 - lineRef.current.position.x) * 0.025
      }
    }

    uniforms.current.u_time.value += 0.015
    if (uniforms.current.u_time.value >= 40 * PI) {
      uniforms.current.u_time.value = 0
    }
  })

  return (
    <mesh ref={lineRef} position={[10, -0.5, -3]}>
      {/* <bufferGeometry >
                <bufferAttribute attachObject={['attributes', 'position']} count={vertices.length/3} array={vertices} itemSize={3} />
            </bufferGeometry>      */}
      <planeGeometry args={[100, LINE_WIDTH, 20, 4]} />
      <shaderMaterial
        transparent={true}
        fragmentShader={LineFragmentShader}
        vertexShader={LineVertexShader}
        uniforms={uniforms.current}
      />
    </mesh>
  )
}

export default AnimatedLine
