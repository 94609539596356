import React, { FunctionComponent, useMemo, useRef, useState } from 'react'
import './footer.scss'
import { gsap } from 'gsap'
import { SiLinkedin, SiGithub } from 'react-icons/si'
import malt from '../../../assets/images/malt.png'

const Footer: FunctionComponent = () => {
  const tMaltLink = useMemo(() => gsap.timeline(), [])

  const AnimDivRef = useRef<HTMLDivElement>(null)
  const maltBtnRef = useRef<HTMLDivElement>(null)
  const linkedinBtnRef = useRef<HTMLDivElement>(null)
  const githubBtnRef = useRef<HTMLDivElement>(null)

  let [animDivJSX, setAnimDivJSX] = useState(<></>)

  let goTo = (destination: string, url: string) => {
    let destinationJSX = <></>
    let color = 'white'
    let btnClientRect = maltBtnRef.current?.getBoundingClientRect()

    if (destination === 'malt') {
      destinationJSX = (
        <img
          src={malt}
          alt={''}
          style={{ height: '2.5rem', width: '2.5rem' }}
        />
      )
      color = '#FF5C57'
    } else if (destination === 'linkedin') {
      destinationJSX = <SiLinkedin style={{ fontSize: '2.5rem' }} />
      color = '#0a66c2'
    } else if (destination === 'github') {
      destinationJSX = <SiGithub color="black" style={{ fontSize: '2.5rem' }} />
    }

    setAnimDivJSX(destinationJSX)
    window.location.href = url

    if (!tMaltLink.isActive()) {
      tMaltLink.clear()
      tMaltLink
        .fromTo(
          AnimDivRef.current,
          {
            top: btnClientRect?.y,
            left: btnClientRect?.x,
            borderRadius: '50%',
          },
          {
            duration: 0.4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 20,
            opacity: 1,
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: color,
            borderRadius: '0%',
          }
        )
        .fromTo(
          AnimDivRef.current,
          {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 120%)',
          },
          {
            duration: 0.4,
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
            display: 'none',
          }
        )
        .to(AnimDivRef.current, {
          backgroundColor: 'white',
          opacity: 0,
        })
    }
  }

  return (
    <>
      <div
        ref={AnimDivRef}
        style={{ display: 'none', opacity: 0, position: 'fixed' }}>
        {animDivJSX}
      </div>
      <div className="footer d-flex justify-content-end align-items-center">
        <div
          onClick={() =>
            goTo('malt', 'https://www.malt.fr/profile/hubertdecarcouet')
          }
          ref={maltBtnRef}>
          <img src={malt} alt="malt logo" />
        </div>
        <div
          onClick={() => goTo('github', 'https://github.com/H-DC')}
          ref={githubBtnRef}>
          <SiGithub />
        </div>
        <div
          onClick={() =>
            goTo(
              'linkedin',
              'https://www.linkedin.com/in/hubert-de-carcouet-18859a103'
            )
          }
          ref={linkedinBtnRef}>
          <SiLinkedin />
        </div>
        <div className="d-flex flex-column">
          <span>Paris | Nantes</span>
          <span>hdecarcouet@gmail.com</span>
        </div>
      </div>
    </>
  )
}

export default Footer
