export const LineVertexShader = /* GLSL */ `
uniform mediump float u_time; 
varying mediump vec3 pos;
varying mediump vec2 vUv;

void main() {
    vUv = uv;
    pos = position;
    pos.y += cos(u_time*0.6+pos.x*0.6)*0.2*sin(pos.x);
    pos.y += cos(pos.x)*sin(pos.x*0.4);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0); 
}

`
